import React from 'react';
// import html2pdf from 'html2pdf.js';
import './invoice.css';
import { Typography } from '@mui/material';
import SelectField from '../../../components/Common/SelectField';
import FormikDatePicker from '../../../components/Common/FormikDatePicker';
import TextField, { InputField } from '../../../components/Common/Fields';
import { Form, Formik } from 'formik';
import { stripResturantsList, useRestaurantsList } from '../../../components/hooks/restaurants';
import { LoadingButton } from '@mui/lab';
import * as Yup from 'yup'


export default function CreateInvoiceForm({ handleSubmit, data }) {
    const validationSchema = Yup.object().shape({
        penalty: Yup.number()
            .required('Penalty is required'),
            // .min(0, 'Penalty must be a positive number'),
        previousDues: Yup.number()
            .required('Previous Dues are required'),
            // .min(0, 'Previous Dues must be a positive number'),
        invDate: Yup.date()
            .required('Invoice Date is required')
            .nullable(),
    });
    
    return (

        <Formik
            initialValues={{
                penalty: data?.penalty ?? '',
                previousDues: data?.previousDues ?? '',
                invDate: data?.invDate ?? new Date(),
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
        >
            {formik => (

                <Form>
                    <div className='row mb-3'>
                        <div className="col-md-12">
                            <InputField label='Penalty' type="number" name='penalty' placeholder='Penalty' />
                        </div>
                        <div className="col-md-12 mb-2">
                            <InputField label='Previous Dues' type="number" name='previousDues' placeholder='Previous Dues' />
                        </div>
                        <div className="col-md-12 d-flex align-items-center justify-content-center">
                            {/* <Typography variant='caption'>Invoice Date </Typography> */}
                            <FormikDatePicker label='Invoice Date' minDate='2023-04-01' name='invDate' />
                        </div>
                    </div>
                    <div className='d-flex justify-content-center align-items-center'>
                        <div>
                            <LoadingButton
                                loading={false}
                                type="submit"
                                variant="contained"
                                disableElevation
                            >
                                Modify Invoice
                            </LoadingButton>
                            {/* <Button variant="outlined" sx={{ ml: 1 }} disableElevation onClick={() => resetFrom(formik)}>Clear Filter</Button> */}
                        </div>
                        {/* <Button variant="outlined" sx={{ ml: 1 }} disableElevation onClick={() => setModal(false)}>Close</Button> */}

                    </div>
                </Form>
            )}
        </Formik>



    );
}
