import React from "react";
import { useVendorsTransaction } from "../hooks/vendors";

import DataTable from "react-data-table-component";
import moment from "moment";

const VendorRdsTable = ({
  vendorstransactionData,
  vendorstransactionIsLoading,
}) => {
  const columns = [
    {
      name: "Date",
      selector: (row) => moment(row.createdAt).format("DD-MM-YYYY hh:mm A"),
      sortable: true,
      minWidth: "170px",
    },
    {
      name: "Order Details",
      selector: (row) => (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            margin: "5px 0px",
          }}
        >
          {row?.reference ? (
            <>
              <span>
                {" "}
                #{row?.reference?.orderId}{" "}
                {row?.reference?.irctcOrderId ? (
                  <span>/{row.reference.irctcOrderId} </span>
                ) : (
                  ""
                )}
              </span>
              <span>
                {" "}
                Delivery Date:{" "}
                {moment(row.reference.deliveryDate).format(
                  "DD-MM-YYYY hh:mm A",
                )}{" "}
              </span>
              <span> Amount: {row.reference.netPayable.$numberDecimal} </span>
              <span>
                {" "}
                GST: {parseFloat(row.reference.gst.$numberDecimal).toFixed(
                  2,
                )}{" "}
              </span>
              <span> Payment Type:{row.reference.paymentType} </span>
              <span>
                {" "}
                Delivery Charges: {
                  row.reference.deliveryCharge.$numberDecimal
                }{" "}
              </span>
            </>
          ) : (
            <>
              <span>Purpose : {row?.purpose}</span>
              <span>Trnx Summary : {row?.trnxSummary}</span>
            </>
          )}
        </div>
      ),
      sortable: true,
      minWidth: "250px",
    },
    {
      name: "Vendor",
      selector: (row) => (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <span> {row.outlet.name} </span>
        </div>
      ),
      sortable: true,
      minWidth: "220px",
    },
    {
      name: "Base Amount",
      selector: (row) => (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <span> {row?.reference?.totalBase?.$numberDecimal || "..."} </span>
        </div>
      ),
    },
    {
      name: "Margin",
      selector: (row) => (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <span>
            {row?.reference ? (
              <>
                {" "}
                {row.reference.totalBase.$numberDecimal -
                  row.reference.totalYr.$numberDecimal}
              </>
            ) : (
              "..."
            )}
          </span>
        </div>
      ),
    },
    {
      name: "CR/DR",
      selector: (row) => (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <span> {row.trnxType} </span>
        </div>
      ),
      sortable: true,
    },
    {
      name: "Opening Balance",
      selector: (row) => (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <span> {row.balanceBefore.$numberDecimal} </span>
        </div>
      ),
    },
    {
      name: "Amount",
      selector: (row) => (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <span>
            {" "}
            {row?.reference?.totalYr?.$numberDecimal ||
              row?.amount?.$numberDecimal}{" "}
          </span>
        </div>
      ),
    },
    {
      name: "Closing Balance",
      selector: (row) => (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <span>
            {" "}
            {parseFloat(row.balanceAfter.$numberDecimal).toFixed(2)}{" "}
          </span>
        </div>
      ),
    },
  ];

  return (
    <div>
      {!vendorstransactionIsLoading ? (
        <DataTable
          columns={columns}
          data={vendorstransactionData.data}
          fixedHeaderScrollHeight="300px"
          pagination
          responsive
          progressPending={vendorstransactionIsLoading}
          // conditionalRowStyles={conditionalRowStyles}
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default VendorRdsTable;
