import React from 'react'
import { formatDateTime } from '../../../utils/core';
import { useDebouncedCallback } from 'use-debounce';
import { statusBadgeClass } from '../../../utils/functions';
import { useUser } from '../../hooks/user';
import { NewOrderStatusModal } from './Modals/NewOrderStatusModal';
import IRCTCStatusModal from './Modals/IRCTCStatusModal';
import LogModal from './Modals/LogModal';
import RestroInfoModal from './Modals/RestroInfoModal';
import { useConfirm, useOrderETA } from '../../hooks/orders';
import toast from 'react-hot-toast';
import OrderSwapModal from './Modals/OrderSwapModal';
import Skeleton from 'react-loading-skeleton';
import moment from 'moment';




export default function OrderDetail({ data, isLoading }) {
    const { user } = useUser();
    const { handleConfirm, isConfirmLoading } = useConfirm();
    const { orderEtaData, isCheckingEta, refreshEta } = useOrderETA(`/${data?._id}`);
    console.log(orderEtaData)
    const [yrAmount, setYramount] = React.useState(0);
    const [totalAmount, setTotalamount] = React.useState(0);

    // Modals
    const [changeStatusModal, setChangeStatusModal] = React.useState(false);
    const [irctcStatusModal, setIrctcStatusModal] = React.useState(false);
    const [restroInfoModal, setRestroInfoModal] = React.useState(false);
    const [orderLogModal, setOrderLogModal] = React.useState(false);
    const [swapOrderModal, setSwapOrderModal] = React.useState(false);


    const badgeClass = statusBadgeClass[data?.status] || "badge-primary";

    // Refresh ETA
    const handleRefreshEta = () => {
        const toastId = toast.loading('Refreshing ETA / ETD...');
        refreshEta(`/${data?._id}`);
        if (orderEtaData?.success) {

            toast.success('ETA / ETD Refreshed!', { id: toastId })
        } else {
            toast.error('Failed to refresh ETA / ETD', { id: toastId });
        }
    }

    // Confirm Order
    const handleOrderConfirm = async (id) => {
        const data = await handleConfirm(id);
        if (data.success) {
            toast.success("Order Confirmed Successfully")
        } else {
            toast.error(data.error);
        }
    }

    // Total Amount Calculation
    const calculateTotalAmount = useDebouncedCallback(() => {
        let temp = 0;
        let yr = 0;
        data.orderItems.forEach((e) => {
            temp = temp + e.baseRate.$numberDecimal * e.quantity;
            yr = yr + e.yrRate.$numberDecimal * e.quantity;
        });
        setYramount(yr);
        setTotalamount(temp);
    }, 300);

    React.useEffect(() => {
        if (data && !isLoading) {
            calculateTotalAmount();
        }
    }, []);
    // console.log(data)
    // const restroDetail = data?.resturant ?? {};
    return (
        <div className='card pd-20 pd-sm-20 bg-light'>
            <div className="d-flex justify-content-between align-items-center">
                <div>
                    <h6 className="card-body-title text-primary">Order Details</h6>
                    <p>Listing of order details here</p>
                </div>
                <div className=''>
                    {data?.status === "ORDER_PENDING" && <button className="btn btn-success mr-2" onClick={() => { handleOrderConfirm(data?._id) }} disabled={isConfirmLoading}>Change to Confirmed</button>}

                    <button className="btn btn-success mr-2" onClick={() => setIrctcStatusModal(true)}>IRCTC Status</button>

                    {["admin", "superadmin", "manager"]?.includes(user?.role) && (<button className="btn btn-primary mr-2" onClick={() => setChangeStatusModal(true)}>Change Status</button>)}

                    {["admin", "superadmin"]?.includes(user?.role) && (<button className="btn btn-danger mr-2" onClick={() => setSwapOrderModal(true)}>Swap Order</button>)}

                    <button className="btn btn-info mr-2" onClick={() => setOrderLogModal(true)}>Order Log</button>

                    {changeStatusModal && <NewOrderStatusModal data={data} newstatusmodal={changeStatusModal} setNewstatusmodal={setChangeStatusModal} />}

                    {irctcStatusModal && <IRCTCStatusModal
                        modal={irctcStatusModal}
                        setModal={setIrctcStatusModal}
                        data={data}
                    />}
                    {restroInfoModal && <RestroInfoModal
                        modal={restroInfoModal}
                        setModal={setRestroInfoModal}
                        data={data}
                    />}
                    {orderLogModal && <LogModal
                        modal={orderLogModal}
                        setModal={setOrderLogModal}
                        data={data}
                    />}
                    {swapOrderModal && <OrderSwapModal
                        modal={swapOrderModal}
                        setModal={setSwapOrderModal}
                        data={data}
                    />}

                </div>
            </div>
            <div className="container-fluid">
                <div className="card">
                    <div className="card-body">
                        {/*                   
                        <div className='row'>
                            <h5 className="card-title mb-3">Order Details</h5>
                        </div>
                        <hr /> */}
                        <div className='d-flex justify-content-between align-items-center'>
                            <div>
                                <p className="display-4 font-p1 mb-1"><strong>Order ID:</strong> <span className='text-primary'> #{data?.orderId}</span><small style={{ fontSize: 14 }}>
                                    <span className='badge badge-success'>
                                        ({data?.orderSource?.toUpperCase()})
                                    </span>
                                </small></p>
                                <p className="mb-1 display-4 font-p2">
                                    <strong>IRCTC Order ID:</strong> <span className='text-dark'>#{data?.irctcOrderId}</span>
                                </p>
                                <p className="mb-1 display-4 font-p2">
                                    <strong>Payment: </strong>  <span className='font-bolder font-p3 text-success'>{data?.paymentType?.replaceAll('_', ' ')}</span> - <span className={`badge ${data?.paid ? 'badge-success' : 'badge-warning'}`}>{data?.paid ? 'Paid' : 'Not Paid'}</span>
                                </p>
                            </div>
                            <div>
                                <span>
                                    {data?.feedback?.starRating?.$numberDecimal &&
                                        Array.from({ length: Math.round(data.feedback.starRating.$numberDecimal) }).map((_, index) => (
                                            <span key={index}>⭐</span>
                                        ))
                                    }
                                </span>
                                {data?.feedback?.isComplain && <p><small>{data?.feedback?.feedbackText}</small></p>}
                                {/* <button className="alert alert-success" role="button">
                                    ORDER PLACED
                                </button> */}
                            </div>
                        </div>
                        <hr />

                        <div className='d-flex justify-content-between align-items-center'>
                            <div>
                                <p className="mb-1 font-p2 font-bolder text-dark">RESTAURANT DETAILS <span className={`badge ${badgeClass}`}>{data?.status?.replaceAll('_', ' ')}</span></p>

                                <p className="mb-1 font-p3 font-bold text-info" role='button' onClick={() => setRestroInfoModal(true)}>{data?.resturant?.name?.toUpperCase()}</p>

                                <p className="mb-1">☎ {data?.resturant?.contact_details?.contact_number ? data?.resturant?.contact_details?.contact_number : ''} {data?.resturant?.contact_details?.contact_number ? `/ ${data?.resturant?.contact_details?.contact_number}` : ''}</p>

                                <p className="mb-1">
                                    GST Registration No: <span className='font-bold   text-sm  font-p3'>{data?.resturant?.gstin}</span>
                                </p>
                                <p className="mb-1">
                                    Booking Date: <span className='font-bold  text-sm  font-p3'>{formatDateTime(data?.bookingDate, 'Do MMM yyyy hh:mm a')}</span>
                                </p>
                                <p className="mb-1">
                                    Order Date: <span className='font-bold  text-sm  font-p3'>{formatDateTime(data?.createdAt, 'Do MMM yyyy hh:mm a')}</span>
                                </p>
                                <p className="mb-1">
                                    Delivery Date: <span className='font-bold  text-sm  font-p3'>{formatDateTime(data?.deliveryDate, 'Do MMM yyyy hh:mm a')}</span>
                                </p>
                            </div>
                            <div>
                                <p className="mb-1 font-p2 font-bolder text-dark">CUSTOMER DETAILS</p>
                                <p className="mb-1 font-p3 font-bold">{data?.deliveryDetails?.passengerName?.toUpperCase()} <small title='Total Passengers'>({data?.deliveryDetails?.passengerCount ?? 1})</small></p>
                                <p className="mb-1">📞 {data?.mobile ? data?.mobile : ''} {data?.customerDetails?.alternateMobile ? `/ ${data?.customerDetails?.alternateMobile}` : ''}</p>

                                <p className="mb-1">
                                    PNR: <span className='font-bold font-p3'>{data?.deliveryDetails?.pnr}</span>
                                </p>
                                <p className="mb-1">
                                    Coach / Berth: <span className='font-bold font-p3'>{data?.deliveryDetails?.coach} / {data?.deliveryDetails?.berth}</span>
                                </p>
                                <p className="mb-1">
                                    Train: <span className='font-bold font-p3'>{data?.deliveryDetails?.trainNo} / {data?.deliveryDetails?.trainName}</span>
                                </p>
                                <p className="mb-1">
                                    Delivery Station: <span className='font-bold font-p3'>{data?.deliveryDetails?.stationCode} / {data?.deliveryDetails?.station}</span>
                                </p>
                            </div>
                        </div>
                        <div className="d-flex flex-row align-items-center">
                            <p className="mb-1 text-center">
                                ETA / ETD: {isCheckingEta ? (
                                    <Skeleton width={400} />
                                ) : (
                                    <>
                                        <span className='font-bold text-sm font-p3'>
                                            {moment(orderEtaData?.data?.deliveryDetails?.eta).utc().format('Do MMM yyyy hh:mm a')} / {moment(orderEtaData?.data?.deliveryDetails?.etd).utc().format('Do MMM yyyy hh:mm a')}
                                        </span>
                                    </>
                                )}
                            </p>
                            <span className="material-symbols-outlined text-danger" role='button' onClick={handleRefreshEta} title='Refresh ETA / ETD'>
                                autorenew
                            </span>
                        </div>
                        <hr />
                        <div className="row">
                            <div className="table-responsive">
                                <table className="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th>Item Name</th>
                                            <th>Description</th>
                                            <th>Qty</th>
                                            <th>Rate</th>
                                            {/* <th>Discount</th> */}
                                            <th>Total</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data?.orderItems?.map((item, index) => (<tr key={index}>
                                            <td>
                                                <p className='d-flex align-items-center text-dark'>
                                                    {item?.itemName}&nbsp; <span role='button' title={item?.isVegetarian ? 'Veg' : 'Non-Veg'} className={`material-icons text-sm ${item?.isVegetarian ? 'text-success' : 'text-danger'}`}>
                                                        fiber_manual_record
                                                    </span>
                                                </p>
                                            </td>
                                            <td>{item?.description}</td>
                                            <td>{item?.quantity}</td>
                                            <td>Rs. {item?.baseRate.$numberDecimal ?? 0}</td>
                                            {/* <td>{item?.discount?.$numberDecimal}</td> */}
                                            <td>Rs. {item?.baseRate.$numberDecimal * item?.quantity ?? 0}</td>
                                        </tr>))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <hr />
                        <div className='d-flex justify-content-between align-items-center'>
                            <div>
                                <p className="mb-1 ">
                                    Restaurant Amount: <span className='font-bold font-p3 text-dark'>Rs. {yrAmount ?? 0}</span>
                                </p>
                                <p className="mb-1">
                                    Commision: <span className='font-bold font-p3 text-dark'>Rs. {totalAmount - yrAmount ?? 0}</span>
                                </p>
                            </div>

                            <div>
                                <p className="mb-1">
                                    Total Amount: <span className='font-bold font-p3 text-success'> Rs. {totalAmount?.toFixed(2) ?? '0.00'}</span>
                                </p>
                                <hr />
                                <p className="mb-1">
                                    GST: <span className='font-bold font-p3'> Rs. {Number(data?.gst?.$numberDecimal ?? 0).toFixed(2)}</span>
                                </p>
                                <p className="mb-1">
                                    Delivery Charge: <span className='font-bold font-p3'> Rs. {Number(data?.deliveryCharge?.$numberDecimal ?? 0).toFixed(2)}</span>
                                </p>
                                {Number(data?.irctcDiscountAmount?.$numberDecimal ?? 0) > 0 && <p className="mb-1">
                                    IRCTC Discount: <span className='font-bold font-p3'> Rs. {Number(data?.irctcDiscountAmount?.$numberDecimal ?? 0).toFixed(2)}</span>
                                </p>}
                                {Number(data?.vendorDiscountAmount?.$numberDecimal ?? 0) > 0 && <p className="mb-1">
                                    Vendor Discount: <span className='font-bold font-p3'> Rs. {Number(data?.vendorDiscountAmount?.$numberDecimal ?? 0).toFixed(2)}</span>
                                </p>}
                                {Number(data?.discount?.$numberDecimal ?? 0) > 0 && <p className="mb-1">
                                    Discount: <span className='font-bold font-p3'> Rs. {Number(data?.discount?.$numberDecimal ?? 0).toFixed(2)}</span>
                                </p>}
                                <hr />
                                <p className="mb-1 text-success display-6 font-p3 font-bold">
                                    Amount Payable: <span className='font-p3 font-bolder'>Rs. {Number(data?.netPayable?.$numberDecimal ?? 0).toFixed(2)}</span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
