import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import * as Yup from "yup";
import { Field, Form, Formik } from "formik";
import SubmitButton from "../Common/Button";
import TextField from "../Common/MuiTextField";
import { useUpdateRest } from "../hooks/restaurants";
import { useEffect, useState } from "react";
import SelectField from "../Common/SelectField";
import { stripStationsList } from "../hooks/vendors";
import { useStationsList } from "../hooks/stations";
import Loader2 from "../Common/Loader2";
import moment from "moment";
import {
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Typography,
} from "@mui/material";
import Newselect from "../Common/Newselect";
import SelectFieldOld from "../Common/SelectField_old";

export default function UpdateResturantForm({ data, setModalIsOpen }) {
  const { id } = useParams();
  const updateRestType = data.restaurant_type;
  const [restTypeObj, setRestTypeObj] = useState({
    veg: false,
    nonveg: false,
    jain: false,
  });
  // console.log(updateRestType);

  const prepaid_options = [
    { label: "Yes", value: true },
    { label: "No", value: false },
  ];

  const weeklyClosed_options = [
    { label: "Monday", value: "MON" },
    { label: "Tuesday", value: "TUE" },
    { label: "Wednesday", value: "WED" },
    { label: "Thursday", value: "THU" },
    { label: "Friday", value: "FRI" },
    { label: "Saturday", value: "SAT" },
    { label: "Sunday", value: "SUN" },
  ];

  const changeObj = () => {
    for (let i = 0; i < updateRestType.length; i++) {
      if (updateRestType[i] === "veg") {
        setRestTypeObj((pre) => ({
          ...pre,
          veg: true,
        }));
      } else if (updateRestType[i] === "Non-Veg") {
        setRestTypeObj((pre) => ({
          ...pre,
          nonveg: true,
        }));
      } else if (updateRestType[i] === "Jain") {
        setRestTypeObj((pre) => ({
          ...pre,
          jain: true,
        }));
      }
    }
  };

  const ChangeRestData = (e) => {
    if (e.target.value === "veg" && restTypeObj.veg === true) {
      let index = updateRestType.indexOf("veg");
      while (index !== -1) {
        updateRestType.splice(index, 1);
        index = updateRestType.indexOf("veg");
      }
      setRestTypeObj((pre) => ({
        ...pre,
        veg: false,
      }));
    } else if (e.target.value === "veg" && restTypeObj.veg === false) {
      updateRestType.push("veg");
      setRestTypeObj((pre) => ({
        ...pre,
        veg: true,
      }));
    } else if (e.target.value === "nonveg" && restTypeObj.nonveg === false) {
      updateRestType.push("Non-Veg");
      setRestTypeObj((pre) => ({
        ...pre,
        nonveg: true,
      }));
    } else if (e.target.value === "nonveg" && restTypeObj.nonveg === true) {
      let index = updateRestType.indexOf("Non-Veg");
      while (index !== -1) {
        updateRestType.splice(index, 1);
        index = updateRestType.indexOf("Non-Veg");
      }
      setRestTypeObj((pre) => ({
        ...pre,
        nonveg: false,
      }));
    } else if (e.target.value === "jain" && restTypeObj.jain === true) {
      let index = updateRestType.indexOf("Jain");
      while (index !== -1) {
        updateRestType.splice(index, 1);
        index = updateRestType.indexOf("Jain");
      }
      setRestTypeObj((pre) => ({
        ...pre,
        jain: false,
      }));
    } else if (e.target.value === "jain" && restTypeObj.jain === false) {
      updateRestType.push("Jain");
      setRestTypeObj((pre) => ({
        ...pre,
        jain: true,
      }));
    }
  };

  useEffect(() => {
    changeObj();
  }, []);
  const [newArr, setNewarr] = useState([]);

  const { handleUpdateRest } = useUpdateRest(id);

  const sendData = async (val) => {
    // console.log(val);
    if (newArr.length !== 0) {
      val.station = data.station;
      const res = await handleUpdateRest(val);
      if (res.success) {
        toast.success("Resturant Updated");
        window.location.reload();
      } else {
        toast.error(res.error);
      }
    } else {
      const res = await handleUpdateRest(val);
      if (res.success) {
        toast.success("Resturant Updated");
        // window.location.reload();
        setModalIsOpen(false);
      } else {
        toast.error(res.error);
      }
    }
    // console.log(newArr.length)
  };

  const updateSchema = Yup.object({
    name: Yup.string().min(4).required("Enter restaurant name."),
    brand: Yup.string().min(4).required("Enter brand name."),
    address: Yup.string().min(4).required("Enter address."),
    city: Yup.string().required("Enter city name"),
    state: Yup.string().required("Enter state name"),
    pinCode: Yup.number().required("Enter pincode"),
    station_distance: Yup.string().min(1).required("Enter station distance."),
    firm_name: Yup.string().min(4).required("Enter company name."),
    firm_type: Yup.string().required("Enter Firm Type."),
    delivery_type: Yup.string().required("Enter Delivery Type."),
    company_pan: Yup.string().min(4).required("Enter company pan."),
    // gstin: Yup.string().min(4).required("Enter gst number."),
    fssai_lic_num: Yup.string().required("Enter fssai licence number."),
    closing_time: Yup.string().required("Enter closing time."),
    opening_time: Yup.string().required("Enter opening time."),
    min_order_amount: Yup.string().required("Enter Minimum Order Amount."),
    min_order_time: Yup.string().required("Enter Minimum Order Time."),
    contact_details: Yup.object().shape({
      contact_person: Yup.string().required("Enter contact person."),
      contact_number: Yup.string().required("Enter contact number."),
      email: Yup.string().email().required("Enter email address."),
    }),
    bank_ac_details: Yup.object().shape({
      ac_holder_name: Yup.string().required("Enter Account Holder name"),
      account: Yup.string().required("Enter Accout No."),
      ifsc: Yup.string().required("Enter IFSC"),
    }),
    fssaiValidUpto: Yup.date().required("Enter date"),
  });

  const { stationsData, stationsIsLoading } = useStationsList();

  const [state, setState] = useState({
    selectedOption: data.station.map((e, i) => {
      return { label: e.stationName, value: e._id };
    }),
  });

  const addStation = () => {
    setNewarr([]);
    var arr = [],
      result = state.selectedOption.map((data) => {
        arr.push(data.value);
        newArr.push(data.value);
      });

    // console.log(newArr)
  };

  const handleChange = (selectedOption) => {
    setState({ selectedOption });
  };

  return (
    <>
      <Formik
        initialValues={{
          name: data.name,
          brand: data.brand,
          station_distance: data.station_distance,
          station: newArr,
          // station: data?.station ? data.station : newArr,
          address: data.address,
          city: data.city ? data.city : "",
          state: data.state ? data.state : "",
          pinCode: data.pinCode ? data.pinCode : "",
          firm_name: data.firm_name,
          firm_type: data.firm_type, // Private Limited, Partnership Firm, Proprietorship
          company_pan: data.company_pan,
          gstin: data.gstin,
          contact_details: {
            contact_person: data.contact_details.contact_person,
            email: data.contact_details.email,
            contact_number: data.contact_details.contact_number,
            alt_number: data.contact_details.alt_number,
            alt_number_sec: data?.contact_details?.alt_number_sec || "",
            alt_number_thrd: data?.contact_details?.alt_number_thrd || "",
          },
          restaurant_type: updateRestType, // * Veg, Non-Veg, Jain
          delivery_type: data.delivery_type, // delivery, pickup
          fssai_status: data.fssai_status,
          fssai_lic_num: data.fssai_lic_num,
          status: data.status,
          min_order_amount: data.min_order_amount
            ? data.min_order_amount.$numberDecimal
            : "",
          min_order_time: data.min_order_time,

          opening_time: data.opening_time,
          closing_time: data.closing_time,
          bank_ac_details: {
            ifsc: data.bank_ac_details.ifsc,
            account: data.bank_ac_details.account,
            ac_holder_name: data.bank_ac_details.ac_holder_name,
          },
          prepaid: data.prepaid ? data.prepaid : false,
          weeklyClosed: data.weeklyClosed ? data.weeklyClosed : [],
          fssaiValidUpto: data.fssaiValidUpto
            ? moment(data.fssaiValidUpto).format("yyyy-MM-DD")
            : "",
        }}
        validationSchema={updateSchema}
        onSubmit={(val) => {
          sendData(val);
          addStation();
          //   console.log("update restaurant form:\t", val);
        }}
        // onSubmit={(val) => {console.log(val)
        //   addStation()
        // }}
        enableReinitialize
      >
        {(formik) => (
          <div className="modal-content bg-light">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Update Restaurant
              </h5>
              <button
                type="button"
                className="close"
                onClick={() => setModalIsOpen(false)}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <Form className="form-row">
                <div className="form-group col-md-6">
                  <TextField
                    label="Name"
                    name="name"
                    placeholder="Item Name"
                    sx={{ mb: 3 }}
                  />
                </div>
                <div className="form-group col-md-6">
                  <TextField
                    label="Address"
                    name="address"
                    placeholder="Address"
                    sx={{ mb: 3 }}
                  />
                </div>
                <div className="form-group col-md-4">
                  <TextField
                    label="City"
                    name="city"
                    placeholder="City"
                    sx={{ mb: 3 }}
                  />
                </div>
                <div className="form-group col-md-4">
                  <TextField
                    label="State"
                    name="state"
                    placeholder="State"
                    sx={{ mb: 3 }}
                  />
                </div>
                <div className="form-group col-md-4">
                  <TextField
                    type="number"
                    label="Pin Code"
                    name="pinCode"
                    placeholder="pinCode"
                    sx={{ mb: 3 }}
                  />
                </div>

                <div className="form-group col-md-6">
                  <label>Stations</label>
                  {/* {!stationsIsLoading ? ( */}

                  <SelectFieldOld
                    options={stationsData?.map(stripStationsList)}
                    // filterOption={createFilter({ ignoreAccents: false })}
                    isLoading={stationsIsLoading}
                    onChange={handleChange}
                    name="station"
                    isMulti
                  />
                  {/* ) : <Loader2 />} */}
                </div>
                <div className="form-group col-md-6 item-divtwo">
                  <label>Firm Type</label>
                  <Field
                    as="select"
                    name="firm_type"
                    className="custom-select mr-sm-2"
                  >
                    <option value="Private Limited">Private Limited</option>
                    <option value="Partnership">Partnership</option>
                    <option value="Properietorship">Proprietorship</option>
                  </Field>
                </div>

                <div className="form-group col-md-6">
                  <TextField
                    label="Brand"
                    name="brand"
                    placeholder="Brand Name"
                    sx={{ mb: 3 }}
                  />
                  <TextField
                    label="Station distance"
                    name="station_distance"
                    placeholder="Station Distance"
                    sx={{ mb: 3 }}
                  />
                </div>

                <div className="form-group col-md-6">
                  <TextField
                    label="Firm Name"
                    name="firm_name"
                    placeholder="Firm Name"
                    sx={{ mb: 3 }}
                  />
                  <TextField
                    label="Company Pan"
                    name="company_pan"
                    placeholder="Company Pan"
                    sx={{ mb: 3 }}
                  />
                </div>
                <div className="form-group col-md-6">
                  <TextField
                    label="Minimum Order Time"
                    name="min_order_time"
                    placeholder="Minimum Order Time"
                    sx={{ mb: 3 }}
                  />
                  <TextField
                    label="Minimum Order Amount"
                    name="min_order_amount"
                    placeholder="Minimum Order Amount"
                    sx={{ mb: 3 }}
                  />
                </div>

                <div className="form-group col-md-6">
                  <TextField
                    label="Gstin"
                    name="gstin"
                    placeholder="Gstin"
                    sx={{ mb: 3 }}
                  />
                </div>
                <div className="form-group col-md-6">
                  <TextField
                    label="Contact Person"
                    name="contact_details.contact_person"
                    placeholder="Contact Person"
                    sx={{ mb: 3 }}
                  />
                  <TextField
                    label="Contact Number"
                    name="contact_details.contact_number"
                    placeholder="Contact Number"
                    sx={{ mb: 3 }}
                  />
                </div>
                <div className="form-group col-md-6">
                  <TextField
                    label="Email"
                    name="contact_details.email"
                    placeholder="Email"
                    sx={{ mb: 3 }}
                  />
                  <TextField
                    label="Alternate Number"
                    name="contact_details.alt_number"
                    placeholder="Alternate Number"
                    sx={{ mb: 3 }}
                  />
                </div>
                <div className="form-group col-md-6">
                  <TextField
                    label="Second Alternate Number"
                    name="contact_details.alt_number_sec"
                    placeholder="Second Alternate Number"
                    sx={{ mb: 3 }}
                  />
                  <TextField
                    label="Third Alternate Number"
                    name="contact_details.alt_number_thrd"
                    placeholder="Alternate Number"
                    sx={{ mb: 3 }}
                  />
                </div>
                <div className="form-group col-md-6">
                  <TextField
                    label="Acccount IFSC"
                    name="bank_ac_details.ifsc"
                    placeholder="IFSC"
                    sx={{ mb: 3 }}
                  />

                  <TextField
                    label="Fssai Lic number"
                    name="fssai_lic_num"
                    placeholder="fssai lic number"
                    sx={{ mb: 3 }}
                  />
                </div>

                <div className="form-group col-md-6">
                  <TextField
                    label="Acccount holder name"
                    name="bank_ac_details.ac_holder_name"
                    placeholder="Account Holder Name"
                    sx={{ mb: 3 }}
                  />

                  <TextField
                    label="Acccount Number"
                    name="bank_ac_details.account"
                    placeholder="Account Number"
                    sx={{ mb: 3 }}
                  />
                </div>
                <div className="form-group col-md-6">
                  <TextField
                    label="Closing Time"
                    name="closing_time"
                    placeholder="Closing Time"
                    sx={{ mb: 3 }}
                  />
                  <TextField
                    type="date"
                    label="Fssai Valid upto"
                    name="fssaiValidUpto"
                    InputLabelProps={{ shrink: true }}
                    placeholder="Fssai Valid upto"
                    sx={{ mb: 3 }}
                  />
                </div>
                <div className="form-group col-md-6">
                  <TextField
                    label="Opening Time"
                    name="opening_time"
                    placeholder="Opening Time"
                    sx={{ mb: 3 }}
                  />
                </div>

                <div className="form-group col-md-6">
                  <label>Fssai Status - </label>
                  <Field
                    as="select"
                    name="fssai_status"
                    className="custom-select mr-sm-2"
                  >
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </Field>
                </div>
                <div className="form-group col-md-6">
                  <label>Active Status - </label>

                  <Field
                    as="select"
                    name="status"
                    className="custom-select mr-sm-2"
                  >
                    <option value="Active">Active</option>
                    <option value="Closed">Deactive</option>
                  </Field>
                </div>
                <div className="form-group col-md-6 item-divtwo">
                  <label>Prepaid</label>
                  <SelectField
                    name="prepaid"
                    options={prepaid_options}
                    value={prepaid_options.filter(function (option) {
                      return option.value === formik.values.prepaid;
                    })}
                    onChange={(e) => formik.setFieldValue("prepaid", e.value)}
                  />
                </div>

                <div className="form-group col-md-6 item-divtwo">
                  <FormControl fullWidth>
                    <label>Weekly Closed</label>
                    <Newselect
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      name="weeklyClosed"
                      value={formik.values.weeklyClosed}
                      onChange={(e) =>
                        formik.setFieldValue("weeklyClosed", e.target.value)
                      }
                      label="Weekly Closed"
                      multiple
                      size="small"
                      input={<OutlinedInput label="Weekly Closed" />}
                    >
                      {weeklyClosed_options.map((element, id) => (
                        <MenuItem key={id} value={element.value}>
                          {" "}
                          {element.label}
                        </MenuItem>
                      ))}
                    </Newselect>
                  </FormControl>
                </div>

                <div className="form-group col-md-6 item-divtwo">
                  <div className="d-flex gap-3 justify-content-center align-items-center p-3">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={restTypeObj.veg === true}
                        onChange={(e) => ChangeRestData(e)}
                        value="veg"
                        id="vegCheckbox"
                      />
                      <label className="form-check-label" htmlFor="vegCheckbox">
                        Veg
                      </label>
                    </div>

                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={restTypeObj.nonveg === true}
                        onChange={(e) => ChangeRestData(e)}
                        value="nonveg"
                        id="nonvegCheckbox"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="nonvegCheckbox"
                      >
                        Non-Veg
                      </label>
                    </div>

                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={restTypeObj.jain === true}
                        onChange={(e) => ChangeRestData(e)}
                        value="jain"
                        id="jainCheckbox"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="jainCheckbox"
                      >
                        Jain
                      </label>
                    </div>
                  </div>
                </div>
                {/* <div className="modal-footer"> */}
                <div className="d-flex justify-content-end flex-row mt-3">
                  <div className="col-auto">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      // data-bs-dismiss="modal"
                      onClick={() => setModalIsOpen(false)}
                    >
                      Close
                    </button>
                  </div>
                  <div className="col-auto">
                    <SubmitButton fullWidth label="Update" />
                  </div>
                </div>
                {/* </div> */}
              </Form>
            </div>
          </div>
        )}
      </Formik>
    </>
  );
}
